import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import RealtimeDashboardView from '../views/dashboard/realtime-dashboard.view';
import ContentChannelsView from '../views/content/channels.view';
import ContentVideosView from '../views/content/videos.view';
import SubscribersView from '../views/subscribers/subscribers.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import ContentCategoriesView from '../views/content/categories.view';
import ContentSequenceView from '../views/content/sequence.view';
import AdsReportsOverviewView from '../views/reports/ads-overview.view';
import ContentTabsView from '../views/content/tabs.view';
import EpgView from '../views/epg/epg.view';
import ReportsAppSessionsView from '../views/reports/app-sessions.view';
import ReportsWatchSessionsView from '../views/reports/watch-sessions.view';
import {
  ADMIN_USER_ROLE,
  DEFAULT_CHANNEL,
  SHOW_ADMIN_USERS,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_VIDEOS,
  SHOW_EPG,
  SHOW_MANAGE_SEQUENCE,
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_APP_SESSIONS,
  SHOW_REPORTS_CONTENT_CREATOR,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_ADS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SUBSCRIBERS,
  SHOW_TABS,
  SUBCONTENT_CATEGORIES,
  SUBCONTENT_SEQUENCE,
} from '../config/const.config';
import { checkAdminUserRole } from '../utils/local-cache.util';
import ReportsVideoWatchSessionsView from '../views/reports/video-watch-session.view';
import ReportsContentCreatorView from '../views/reports/content-creator.view';

const AppRouter = ({ isLoggedIn, categoriesTab, rerenderComponent }) => {
  const routeMappings = [];

  const adminUserRole = checkAdminUserRole();

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: (
        <Navigate
          to={
            adminUserRole === 'ADMIN'
              ? ROUTE_PATH.DASHBOARD
              : ROUTE_PATH.CONTENT_VIDEOS
          }
        />
      ),
      exact: true,
    });

    if (SHOW_OVERVIEW_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.DASHBOARD,
        element: <DashboardView adminUserRole={adminUserRole} />,
        exact: true,
      });
    }

    if (SHOW_REALTIME_DASHBOARD && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.REALTIME_DASHBOARD,
        element: <RealtimeDashboardView />,
        exact: true,
      });
    }

    if (!DEFAULT_CHANNEL && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CHANNELS,
        element: <ContentChannelsView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_CATEGORIES && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      if (SUBCONTENT_CATEGORIES) {
        for (let i = 0; i < categoriesTab.length; i += 1) {
          routeMappings.push({
            path: `${ROUTE_PATH.CONTENT_CATEGORIES}/${categoriesTab[i].slug}`,
            element: (
              <ContentCategoriesView
                categoryTypeSlug={categoriesTab[i].slug}
                categoryTypeName={categoriesTab[i].title}
                categoryTypeId={categoriesTab[i].id}
              />
            ),
            exact: true,
          });
        }
      } else {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_CATEGORIES,
          element: <ContentCategoriesView />,
          exact: true,
        });
      }
    }

    if (SHOW_CONTENT_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView videoContentType="VIDEO" />,
        exact: true,
      });

      if (adminUserRole === ADMIN_USER_ROLE.ADMIN) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_ADS,
          element: <ContentVideosView videoContentType="AD" />,
          exact: true,
        });
      }
    }

    if (SHOW_MANAGE_SEQUENCE && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      if (SUBCONTENT_SEQUENCE) {
        for (let i = 0; i < categoriesTab.length; i += 1) {
          routeMappings.push({
            path: `${ROUTE_PATH.CONTENT_SEQUENCE}/${categoriesTab[i].slug}`,
            element: (
              <ContentSequenceView
                categoryType={categoriesTab[i].slug}
                categoryName={categoriesTab[i].title}
              />
            ),
            exact: true,
          });
        }
      } else {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE,
          element: <ContentSequenceView />,
          exact: true,
        });
      }
    }

    if (SHOW_TABS && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TABS,
        element: <ContentTabsView rerenderComponent={rerenderComponent} />,
        exact: true,
      });
    }

    if (SHOW_SUBSCRIBERS && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.SUBSCRIBERS,
        element: <SubscribersView />,
        exact: true,
      });
    }

    if (SHOW_ADMIN_USERS && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.ADMIN_USERS,
        element: <AdminUsersView />,
        exact: true,
      });
    }

    if (SHOW_EPG && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.EPG,
        element: <EpgView />,
        exact: true,
      });
    }

    if (
      SHOW_REPORTS_OVERVIEW_VIDEOS &&
      adminUserRole === ADMIN_USER_ROLE.ADMIN
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS,
        element: <ReportsOverviewView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_OVERVIEW_ADS && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW_ADS,
        element: <AdsReportsOverviewView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_LIVE_USERS && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_LIVE_USERS,
        element: <ReportsLiveUsersView />,
        exact: true,
      });
    }

    if (
      SHOW_REPORTS_WATCH_SESSIONS &&
      adminUserRole === ADMIN_USER_ROLE.ADMIN
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_WATCH_SESSIONS,
        element: <ReportsWatchSessionsView />,
        exact: true,
      });
    }

    if (
      SHOW_REPORTS_WATCH_SESSIONS &&
      adminUserRole === ADMIN_USER_ROLE.ADMIN
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_VIDEO_WATCH_SESSIONS,
        element: <ReportsVideoWatchSessionsView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_APP_SESSIONS && adminUserRole === ADMIN_USER_ROLE.ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_APP_SESSIONS,
        element: <ReportsAppSessionsView />,
        exact: true,
      });
    }

    if (
      SHOW_REPORTS_CONTENT_CREATOR &&
      adminUserRole === ADMIN_USER_ROLE.ADMIN
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_CONTENT_CREATOR,
        element: <ReportsContentCreatorView />,
        exact: true,
      });
    }
    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? (
      <PageNotFoundView adminUserRole={adminUserRole} />
    ) : (
      <LoginView />
    ),
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  categoriesTab: PropTypes.array.isRequired,
  rerenderComponent: PropTypes.func.isRequired,
};

export default AppRouter;
