import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import Counters from '../../components/reports/counters.component';
import WatchedSecondsChart from '../../components/reports/watched-seconds-chart.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import { SHOW_REPORTS_OVERVIEW_VIDEOS } from '../../config/const.config';
import ROUTE_PATH from '../../config/routes.config';
import Loading from '../../components/table-elements/loading.component';
import PieChart from '../../components/reports/piechart.component';

const DashboardView = ({ adminUserRole }) => {
  const pageTitle = PAGE_TITLE_LIST.OVERVIEW_DASHBOARD;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (adminUserRole === 'CONTENT_OWNER') {
      navigate(ROUTE_PATH.CONTENT_VIDEOS, { replace: true });
    }
    setLoading(false);
  }, []);

  return (
    <>
      {loading && <Loading />}

      {!loading && (
        <AppLayout pageTitle={pageTitle}>
          <Counters />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              {SHOW_REPORTS_OVERVIEW_VIDEOS && <WatchedSecondsChart />}
              <PieChart />
            </Grid>
          </Grid>
        </AppLayout>
      )}
    </>
  );
};

DashboardView.propTypes = {
  adminUserRole: PropTypes.string.isRequired,
};

export default DashboardView;
