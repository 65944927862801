import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Divider,
  ImageListItem,
  Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { formatNumber } from '../../utils/numbers.util';
import ROUTE_PATH from '../../config/routes.config';
import { CONTENT_ALIAS } from '../../config/const.config';

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

const LiveCountWidget = ({
  title,
  total,
  color,
  icon,
  navLink,
  sx,
  hideLogo,
}) => (
  <>
    {typeof total === 'number' && (
      <Card
        sx={{
          p: 3,
          boxShadow: 0,
          textAlign: 'center',
          color: (theme) => theme.palette[color].darker,
          bgcolor: (theme) => theme.palette[color].lighter,
          display: `${title !== 'Total' && 'flex'}`,
          alignItems: `${title !== 'Total' && 'center'}`,
          justifyContent: `${title !== 'Total' && 'space-between'}`,
          ...sx,
        }}
      >
        {!hideLogo && (
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <StyledIcon
              sx={{
                color: (theme) => theme.palette[color].dark,
                margin: 0,
                backgroundImage: (theme) =>
                  `linear-gradient(135deg, ${alpha(
                    theme.palette[color].dark,
                    0
                  )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
                p: 1,
              }}
            >
              <ImageListItem>
                <img alt="" src={icon} width={24} height={24} loading="lazy" />
              </ImageListItem>
            </StyledIcon>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              <NavLink to={navLink}>
                <Button>
                  <Box sx={{ color: 'black' }}>{title}</Box>
                </Button>
              </NavLink>
            </Typography>
          </Typography>
        )}

        <Typography>
          <Typography variant="h3">{formatNumber(total || 0)}</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            <Box sx={{ color: 'black' }}>
              {total > 1
                ? CONTENT_ALIAS.USER_PLURAL
                : CONTENT_ALIAS.USER_SINGULAR}
            </Box>
          </Typography>
        </Typography>
      </Card>
    )}

    {typeof total === 'object' && (
      <Card
        sx={{
          py: 4,
          boxShadow: 0,
          textAlign: 'center',
          minHeight: '268px',
          color: (theme) => theme.palette[color].darker,
          bgcolor: (theme) => theme.palette[color].lighter,
          ...sx,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            opacity: 0.72,
            display: 'inline-block',
            fontSize: '25px',
            marginTop: '-5%',
            marginBottom: '3%',
          }}
        >
          {title}
        </Typography>

        <Divider variant="middle" sx={{ marginBottom: '2%' }} />

        {total.map((item) => (
          <Typography
            key={item.slug}
            variant="subtitle2"
            sx={{ opacity: 0.72 }}
          >
            <NavLink to={`${ROUTE_PATH.CONTENT_CATEGORIES}/${item.slug}`}>
              <Button>
                <Box sx={{ fontSize: '16px', color: '#04297A' }}>
                  {item.tabName}
                  {' - '}
                  {formatNumber(item.cnt || 0)}
                </Box>
              </Button>
            </NavLink>
          </Typography>
        ))}
      </Card>
    )}
  </>
);

LiveCountWidget.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string,
  navLink: PropTypes.string,
  sx: PropTypes.object,
  hideLogo: PropTypes.bool,
};
LiveCountWidget.defaultProps = {
  color: 'primary',
  icon: '',
  navLink: '',
  sx: {},
  hideLogo: false,
};

export default LiveCountWidget;
