import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListContentCreator from '../../components/reports/content-creator.component';

const ReportsContentCreatorView = () => {
  const pageTitle = PAGE_TITLE_LIST.REPORTS_CONTENT_CREATOR;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListContentCreator />
    </AppLayout>
  );
};

export default ReportsContentCreatorView;
