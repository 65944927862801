import React, { useState } from 'react';
import { Stack, Card, CardHeader, Box, Button, Switch } from '@mui/material';
import JsFileDownloader from 'js-file-download';
import { getDate, formatDate } from '../../utils/datetime.util';
import { getVideosReportData } from '../../services/reports.service';
import Channels from '../form-elements/channels.component';
import Platforms from '../form-elements/platforms.component';
import Datepicker from '../form-elements/datepicker.component';
import { CONTENT_ALIAS, DEFAULT_CHANNEL } from '../../config/const.config';

const DownloadReports = () => {
  const [filters, setFilters] = useState({
    channel: null,
    platform: null,
    type: null,
    combinedPlatforms: true,
    startDate: getDate(-2),
    endDate: getDate(0),
  });

  const handleChannelChange = (v) => {
    setFilters({
      ...filters,
      channel: v,
    });
  };
  const handlePlatformChange = (v) => {
    setFilters({
      ...filters,
      platform: v,
    });
  };
  // const handleTypeChange = (v) => {
  //   setFilters({
  //     ...filters,
  //     type: v,
  //   });
  // };
  const handleCombinedPlatformsChange = (e) => {
    setFilters({
      ...filters,
      combinedPlatforms: e.target.checked,
    });
  };
  const handleStartDateChange = (v) => {
    setFilters({
      ...filters,
      startDate: v,
    });
  };
  const handleEndDateChange = (v) => {
    setFilters({
      ...filters,
      startDate: v,
    });
  };

  const downloadReport = () => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
    let downloadFileName = `analytics_${startDate}_${endDate}`;
    let reportType = 'sum_of_two_dates_per_video_total_seconds_watched';

    if (!filters.platform && filters.combinedPlatforms) {
      reportType =
        'sum_of_two_dates_per_video_total_seconds_watched_platforms_combined';
    }

    const payload = {
      startDate,
      endDate,
      reportType,
      output: 'csv',
    };
    if (filters.channel?.id) {
      payload.channels = [filters.channel.id];
      downloadFileName += `_with-channel-${filters.channel.code}`;
    }
    if (filters.platform?.id) {
      payload.platforms = [filters.platform.id];
      downloadFileName += `_with-platform-${filters.platform.title}`;
    }
    if (filters.type?.id) {
      payload.types = [filters.type.id];
      downloadFileName += `_with-type-${filters.type.title}`;
    }
    if (!filters.platform && filters.combinedPlatforms) {
      downloadFileName += `_platform_combined`;
    }

    getVideosReportData(payload)
      .then((res) => {
        // eslint-disable-next-line no-unused-vars
        const download = new JsFileDownloader(res, `${downloadFileName}.csv`);
      })
      .catch(() => {
        // to do
      });
  };

  return (
    <Card>
      <CardHeader
        title="Download Reports"
        subheader={`Download ${CONTENT_ALIAS.VIDEO_SINGULAR} watch report`}
      />

      <Box sx={{ p: 3, pb: 1, height: 490 }} dir="ltr">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Datepicker
            id="dr_start_date"
            name="dr_start_date"
            label="Start Date"
            defaultValue={filters.startDate}
            onChange={handleStartDateChange}
            sx={{ width: 200 }}
          />
          To
          <Datepicker
            id="dr_end_date"
            name="dr_end_date"
            label="End Date"
            defaultValue={filters.endDate}
            onChange={handleEndDateChange}
            sx={{ width: 200 }}
          />
        </Stack>
        {!DEFAULT_CHANNEL && (
          <Channels
            id="dr_channel"
            name="dr_channel"
            label="Select Channel"
            defaultValue={filters.channel}
            multiple={false}
            onChange={handleChannelChange}
            sx={{ width: '100%', my: 2 }}
          />
        )}
        <Platforms
          id="dr_platform"
          name="dr_platform"
          label="Select Platform"
          defaultValue={filters.platform}
          multiple={false}
          onChange={handlePlatformChange}
          sx={{ width: '100%', my: 2 }}
        />
        {/* <WatchTypes
          id="dr_watch_type"
          name="dr_watch_type"
          label="Select Type"
          defaultValue={filters.type}
          multiple={false}
          onChange={handleTypeChange}
          sx={{ width: '100%', my: 2 }}
        /> */}
        <Button
          variant="contained"
          sx={{ my: 2, marginRight: 2 }}
          onClick={downloadReport}
        >
          Download CSV
        </Button>
        <Switch
          checked={filters.combinedPlatforms}
          onChange={handleCombinedPlatformsChange}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={!!filters.platform}
        />
        &nbsp; All Platforms Combined
      </Box>
    </Card>
  );
};

export default DownloadReports;
