import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Iconify from '../common/iconify.component';

const SearchList = ({
  label,
  handleSearch,
  inputRef,
  onChange,
  options,
  clearSearch,
  loading,
}) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      handleSearch();
    }}
    autoComplete="off"
    style={{ display: 'inline' }}
  >
    <TextField
      label={`Search ${label}`}
      type="text"
      inputRef={inputRef}
      onChange={onChange}
      onBlur={!options.searchValue && handleSearch}
      sx={{
        width: '200px',
        marginLeft: '5px',
        '.MuiInputLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled': {
          top: '0px',
        },
        '.MuiInputLabel-root': {
          top: '-8px',
        },
      }}
      InputProps={{
        style: {
          height: '2.25em',
        },
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={handleSearch}
            disablePointerEvents={loading}
          >
            <IconButton edge="end" color="primary">
              <Iconify icon="fe:search" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: !(inputRef.current?.value === '' || inputRef.current === null),
      }}
    />

    {options.searchValue && (
      <IconButton onClick={() => clearSearch()} edge="end" color="primary">
        <Iconify icon="mdi:close" />
      </IconButton>
    )}
  </form>
);

SearchList.propTypes = {
  label: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  inputRef: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  clearSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SearchList.defaultProps = {
  loading: false,
};

export default SearchList;
