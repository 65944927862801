// Define application wide routes
const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/',
  REALTIME_DASHBOARD: '/realtime-dashboard',
  CONTENT_CHANNELS: '/content/channels',
  CONTENT_CATEGORIES: '/content/categories',
  CONTENT_VIDEOS: '/content/videos',
  CONTENT_ADS: '/content/ads',
  CONTENT_SEQUENCE: '/content/sequence',
  CONTENT_SEQUENCE_CATEGORY: '/categories',
  CONTENT_SEQUENCE_PLAYLIST: '/playlist',
  CONTENT_TABS: '/content/tabs',
  SUBSCRIBERS: '/subscribers',
  ADMIN_USERS: '/users',
  EPG: '/epg',
  REPORTS_OVERVIEW_VIDEOS: '/reports/overview/videos',
  REPORTS_OVERVIEW_ADS: '/reports/overview/ads',
  REPORTS_LIVE_USERS: '/reports/live-users',
  REPORTS_WATCH_SESSIONS: '/reports/watch/sessions',
  REPORTS_VIDEO_WATCH_SESSIONS: '/reports/video/sessions',
  REPORTS_APP_SESSIONS: '/reports/app/sessions',
  REPORTS_CONTENT_CREATOR: '/reports/content-creator',
  LOGOUT: '/logout',
};

export default ROUTE_PATH;
