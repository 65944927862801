import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewCategoryById,
  viewVideoById,
} from '../../services/content.service';
import VideoUpload from './video-upload.component';
import ImageUpload from './image-upload.component';
import { CONTENT_ALIAS } from '../../config/const.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const UploadAssets = ({
  title,
  dataId,
  dataTitle,
  onCancel,
  videoContentType,
  from,
}) => {
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState({
    videoUrl: '',
    thumbnail: '',
    heroImage: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [renderImage, setRenderImage] = useState(false);

  const showUpdatedImage = (rerender) => {
    if (rerender) {
      setLoading(true);
    }
    setRenderImage(rerender);
  };

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  useEffect(() => {
    if (from === 'video') {
      viewVideoById(dataId)
        .then((res) => {
          setVideoData({
            ...videoData,
            videoUrl: res.data?.videoUrl || '',
            thumbnail: res.data?.thumbnailUrl || '',
            heroImage: res.data?.heroImageUrl || '',
          });
          setLoading(false);
        })
        .catch(() => {
          setVideoData({
            ...videoData,
            videoUrl: '',
            thumbnail: '',
            heroImage: '',
          });
          setLoading(false);
        });
    } else {
      viewCategoryById(dataId)
        .then((res) => {
          setVideoData({
            ...videoData,
            bannerImageUrl: res.data?.bannerImageUrl || '',
          });
          setLoading(false);
        })
        .catch(() => {
          setVideoData({
            ...videoData,
            bannerImageUrl: '',
          });
          setLoading(false);
        });
    }
  }, [renderImage]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      {from === 'video' ? (
        <>
          <DialogTitle id="view-dialog-title">{`${
            videoContentType === 'VIDEO'
              ? CONTENT_ALIAS.VIDEO_PLURAL
              : CONTENT_ALIAS.AD_PLURAL
          } - ${title}`}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onCancel}
            size="small"
            sx={{
              position: 'absolute',
              right: 10,
              top: 15,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:outline-close" />
          </IconButton>

          <DialogContent dividers id="add-dialog-description">
            <form id="upload-assets-form">
              <Box
                component="section"
                sx={{
                  p: 3,
                  border: '2px solid lightgrey',
                  borderRadius: '5px',
                  width: '100%',
                  marginTop: 3,
                }}
              >
                <ImageUpload
                  title="Thumbnail"
                  dataId={dataId}
                  showToastMsg={showToastMsg}
                  setSnackbarInfo={setSnackbarInfo}
                  videoData={videoData}
                  loading={loading}
                  showUpdatedImage={showUpdatedImage}
                />
                <ImageUpload
                  title="Hero Image"
                  dataId={dataId}
                  showToastMsg={showToastMsg}
                  setSnackbarInfo={setSnackbarInfo}
                  videoData={videoData}
                  loading={loading}
                  showUpdatedImage={showUpdatedImage}
                />
              </Box>
              <Box
                component="section"
                sx={{
                  p: 3,
                  border: '2px solid lightgrey',
                  borderRadius: '5px',
                  width: '100%',
                  marginTop: 3,
                }}
              >
                <VideoUpload
                  dataId={dataId}
                  dataTitle={dataTitle}
                  showToastMsg={showToastMsg}
                  videoData={videoData}
                  setSnackbarInfo={setSnackbarInfo}
                  videoContentType={videoContentType}
                />
              </Box>
            </form>
          </DialogContent>

          {snackbarInfo.show && (
            <SnackbarInfo
              type={snackbarInfo.type}
              message={snackbarInfo.message}
              autoHideMessage={false}
            />
          )}
        </>
      ) : (
        <>
          <DialogTitle id="view-dialog-title">{`${title}`}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onCancel}
            size="small"
            sx={{
              position: 'absolute',
              right: 10,
              top: 15,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:outline-close" />
          </IconButton>

          <DialogContent dividers id="add-dialog-description">
            <form id="upload-assets-form">
              <Box
                component="section"
                sx={{
                  p: 3,
                  border: '2px solid lightgrey',
                  borderRadius: '5px',
                  width: '100%',
                  marginTop: 3,
                }}
              >
                <ImageUpload
                  title="Banner Image"
                  dataId={dataId}
                  showToastMsg={showToastMsg}
                  setSnackbarInfo={setSnackbarInfo}
                  videoData={videoData}
                  loading={loading}
                  showUpdatedImage={showUpdatedImage}
                />
              </Box>
            </form>
          </DialogContent>

          {snackbarInfo.show && (
            <SnackbarInfo
              type={snackbarInfo.type}
              message={snackbarInfo.message}
              autoHideMessage={false}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

UploadAssets.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  dataTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  videoContentType: PropTypes.string.isRequired,
  from: PropTypes.string,
};

UploadAssets.defaultProps = {
  from: 'video',
};

export default UploadAssets;
