import CELL_DATA_TYPES from '../cell-types.config';

// Content Creator module configurations
export const LISTING_COLUMNS = [
  {
    id: 'id',
    label: 'ID',
    width: '20%',
    align: 'left',
    dataKey: 'id',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'fullName',
    label: 'Name',
    width: '15%',
    align: 'left',
    dataKey: 'fullName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '20%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'totalVideos',
    label: 'Total Videos',
    width: '15%',
    align: 'right',
    dataKey: 'totalVideos',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalVideosWatch',
    label: 'Total Watch Videos',
    width: '15%',
    align: 'right',
    dataKey: 'totalVideosWatch',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
];

export const LISTING_ACTIONS = [];
