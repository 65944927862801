import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListChannels from '../../components/content/list-channels.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentChannelsView = () => {
  const pageTitle = PAGE_TITLE_LIST.CONTENT_CHANNELS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListChannels />
    </AppLayout>
  );
};

export default ContentChannelsView;
