import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListSubheader,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ROUTE_PATH from '../../../config/routes.config';
import Iconify from '../../common/iconify.component';
import {
  ADMIN_USER_ROLE,
  CONTENT_ALIAS,
  DEFAULT_CHANNEL,
  FOCUS_PALETTE_BGCOLOR,
  SHOW_ADMIN_USERS,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_VIDEOS,
  SHOW_EPG,
  SHOW_MANAGE_SEQUENCE,
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_APP_SESSIONS,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_ADS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_VIDEO_WATCH_SESSIONS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SUBSCRIBERS,
  SHOW_TABS,
  SUBCONTENT_CATEGORIES,
  SUBCONTENT_SEQUENCE,
} from '../../../config/const.config';
import palette from '../../../theme/palette';
import { PRIMARY } from '../../../theme/theme-colors';

const StyledSubHeader = styled((props) => <ListSubheader {...props} />)(
  ({ theme }) => ({
    background: 'transparent',
    color: theme.palette.grey[200],
  })
);

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 24,
  height: 24,
  color: palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
});

const NavItem = ({ title, path, icon, subNav }) => (
  <StyledNavItem
    component={RouterLink}
    to={path}
    sx={{
      '&:hover': {
        color: palette.common.white,
        bgcolor: FOCUS_PALETTE_BGCOLOR,
      },
      '&.active': {
        color: palette.common.white,
        bgcolor: PRIMARY.main,
        fontWeight: 'fontWeightBold',
        '.MuiListItemText-root': {
          color: 'black',
        },
        '.MuiBox-root': {
          color: 'black',
        },
      },
      height: subNav ? 36 : 48,
    }}
  >
    {!subNav && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}
    <ListItemText
      disableTypography
      primary={title}
      sx={{
        color: palette.common.white,
        pl: subNav ? 6 : 0,
        overflowX: 'clip',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        paddingRight: 2,
      }}
    />
  </StyledNavItem>
);
NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element,
  subNav: PropTypes.bool,
};
NavItem.defaultProps = {
  icon: <div />,
  subNav: false,
};

const NavGroup = ({ title, routePath, icon, navGroup }) => {
  const urlParts = window.location.href.split('/');
  const routePathParts = routePath.split('/');

  const initOpen =
    urlParts[urlParts.length - 2] === routePathParts[routePathParts.length - 1];

  const [open, setOpen] = useState(initOpen);
  const handleClick = () => setOpen(!open);

  return (
    <>
      <StyledNavItem onClick={handleClick}>
        <StyledNavItemIcon>{icon}</StyledNavItemIcon>
        <ListItemText
          disableTypography
          primary={title}
          sx={{
            color: palette.common.white,
            overflowX: 'clip',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        />
        <StyledNavItemIcon>
          <Iconify icon={open ? 'mdi:menu-up' : 'mdi:menu-down'} />
        </StyledNavItemIcon>
      </StyledNavItem>

      <Collapse in={open} timeout="auto">
        <List disablePadding sx={{ p: 1 }}>
          {navGroup.map(
            (nav) =>
              nav.status === 1 && (
                <NavItem
                  key={nav.title}
                  title={nav.title}
                  path={`${routePath}/${nav.slug}`}
                  icon={<div />}
                  subNav
                />
              )
          )}
        </List>
      </Collapse>
    </>
  );
};
NavGroup.propTypes = {
  title: PropTypes.string.isRequired,
  routePath: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  navGroup: PropTypes.array.isRequired,
};

const NavItems = ({ categoriesTab, adminUserRole }) => (
  <Box>
    <List disablePadding sx={{ p: 1 }}>
      {SHOW_OVERVIEW_DASHBOARD && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Overview Dashboard"
          path={ROUTE_PATH.DASHBOARD}
          icon={<Iconify icon="ic:round-dashboard" />}
        />
      )}
      {SHOW_REALTIME_DASHBOARD && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Live Users (Per Platform)"
          path={ROUTE_PATH.REALTIME_DASHBOARD}
          icon={<Iconify icon="tabler:activity-heartbeat" />}
        />
      )}
      {SHOW_SUBSCRIBERS && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Subscribers"
          path={ROUTE_PATH.SUBSCRIBERS}
          icon={<Iconify icon="ci:users" />}
        />
      )}
      {SHOW_EPG && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Program Guide (EPG) Setup"
          path={ROUTE_PATH.EPG}
          icon={<Iconify icon="icon-park-outline:schedule" />}
        />
      )}

      <StyledSubHeader component="div">Content</StyledSubHeader>
      {!DEFAULT_CHANNEL && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Channels"
          path={ROUTE_PATH.CONTENT_CHANNELS}
          icon={<Iconify icon="ic:twotone-checklist" />}
        />
      )}
      {SHOW_CONTENT_VIDEOS && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Ads"
          path={ROUTE_PATH.CONTENT_ADS}
          icon={<Iconify icon="mdi:advertisements" />}
        />
      )}
      {SHOW_CONTENT_CATEGORIES &&
        !SUBCONTENT_CATEGORIES &&
        adminUserRole === ADMIN_USER_ROLE.ADMIN && (
          <NavItem
            title={`${CONTENT_ALIAS.CATEGORY_PLURAL}`}
            path={ROUTE_PATH.CONTENT_CATEGORIES}
            icon={<Iconify icon="iconamoon:category" />}
          />
        )}
      {SHOW_CONTENT_CATEGORIES &&
        SUBCONTENT_CATEGORIES &&
        adminUserRole === ADMIN_USER_ROLE.ADMIN && (
          <NavGroup
            title={`${CONTENT_ALIAS.CATEGORY_PLURAL}`}
            routePath={ROUTE_PATH.CONTENT_CATEGORIES}
            icon={<Iconify icon="iconamoon:category" />}
            navGroup={categoriesTab}
          />
        )}
      {SHOW_CONTENT_VIDEOS && (
        <NavItem
          title={`${CONTENT_ALIAS.VIDEO_PLURAL}`}
          path={ROUTE_PATH.CONTENT_VIDEOS}
          icon={<Iconify icon="octicon:video-16" />}
        />
      )}
      {SHOW_MANAGE_SEQUENCE &&
        !SUBCONTENT_SEQUENCE &&
        adminUserRole === ADMIN_USER_ROLE.ADMIN && (
          <NavItem
            title="Content Sequence"
            path={ROUTE_PATH.CONTENT_SEQUENCE}
            icon={<Iconify icon="fluent-mdl2:number-sequence" />}
          />
        )}
      {SHOW_MANAGE_SEQUENCE &&
        SUBCONTENT_SEQUENCE &&
        adminUserRole === ADMIN_USER_ROLE.ADMIN && (
          <NavGroup
            title="Content Sequence"
            routePath={ROUTE_PATH.CONTENT_SEQUENCE}
            icon={<Iconify icon="fluent-mdl2:number-sequence" />}
            navGroup={categoriesTab}
          />
        )}
      {SHOW_TABS && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Tabs"
          path={ROUTE_PATH.CONTENT_TABS}
          icon={<Iconify icon="material-symbols:tab-group-outline" />}
        />
      )}

      {(SHOW_REPORTS_OVERVIEW_VIDEOS ||
        SHOW_REPORTS_OVERVIEW_ADS ||
        SHOW_REPORTS_LIVE_USERS ||
        SHOW_REPORTS_WATCH_SESSIONS ||
        SHOW_REPORTS_APP_SESSIONS) &&
        adminUserRole === ADMIN_USER_ROLE.ADMIN && (
          <StyledSubHeader component="div">Analytics Reports</StyledSubHeader>
        )}
      {SHOW_REPORTS_OVERVIEW_VIDEOS &&
        adminUserRole === ADMIN_USER_ROLE.ADMIN && (
          <NavItem
            title={`Overview (${CONTENT_ALIAS.VIDEO_PLURAL})`}
            path={ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS}
            icon={<Iconify icon="ic:outline-analytics" />}
          />
        )}
      {SHOW_REPORTS_OVERVIEW_ADS && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Overview (Ads)"
          path={ROUTE_PATH.REPORTS_OVERVIEW_ADS}
          icon={<Iconify icon="ic:outline-analytics" />}
        />
      )}
      {SHOW_REPORTS_LIVE_USERS && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Live Users"
          path={ROUTE_PATH.REPORTS_LIVE_USERS}
          icon={<Iconify icon="ic:round-live-tv" />}
        />
      )}
      {SHOW_REPORTS_WATCH_SESSIONS &&
        adminUserRole === ADMIN_USER_ROLE.ADMIN && (
          <NavItem
            title="Watch Sessions"
            path={ROUTE_PATH.REPORTS_WATCH_SESSIONS}
            icon={<Iconify icon="ic:outline-list-alt" />}
          />
        )}
      {SHOW_REPORTS_VIDEO_WATCH_SESSIONS &&
        adminUserRole === ADMIN_USER_ROLE.ADMIN && (
          <NavItem
            title="Video Watch Session"
            path={ROUTE_PATH.REPORTS_VIDEO_WATCH_SESSIONS}
            icon={<Iconify icon="octicon:video-16" />}
          />
        )}
      {SHOW_REPORTS_APP_SESSIONS && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="App Sessions"
          path={ROUTE_PATH.REPORTS_APP_SESSIONS}
          icon={<Iconify icon="ic:outline-list-alt" />}
        />
      )}
      {SHOW_REPORTS_APP_SESSIONS && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="Content Creator Summary"
          path={ROUTE_PATH.REPORTS_CONTENT_CREATOR}
          icon={<Iconify icon="ic:outline-list-alt" />}
        />
      )}

      {SHOW_ADMIN_USERS && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <StyledSubHeader component="div">User Management</StyledSubHeader>
      )}
      {SHOW_ADMIN_USERS && adminUserRole === ADMIN_USER_ROLE.ADMIN && (
        <NavItem
          title="CMS Users"
          path={ROUTE_PATH.ADMIN_USERS}
          icon={<Iconify icon="mdi:users-check" />}
        />
      )}
    </List>
  </Box>
);

NavItems.propTypes = {
  categoriesTab: PropTypes.array.isRequired,
  adminUserRole: PropTypes.string.isRequired,
};

export default NavItems;
