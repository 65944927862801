import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListVideosWatchSession from '../../components/reports/list-video-watch-session.component';

const ReportsVideoWatchSessionsView = () => {
  const pageTitle = PAGE_TITLE_LIST.REPORTS_VIDEO_WATCH_SESSIONS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListVideosWatchSession />
    </AppLayout>
  );
};

export default ReportsVideoWatchSessionsView;
