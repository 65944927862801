import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/videos.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import {
  deleteVideoById,
  getDataList,
  getPlayableStream,
  syncVideoCDNStatus,
  toggleStatus,
} from '../../services/content.service';
import {
  ACTIONS,
  CONTENT_ALIAS,
  LS_ADMIN_USER_ID,
  MULTIPLE_CAT_PER_VIDEO,
} from '../../config/const.config';
import AddEditVideos from './add-edit-videos.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewVideos from './view-videos.component';
import AssignCategoryVideos from './assign-category-videos.component';
import VideoPreview from './videos-preview.component';
import ImagePreview from './image-preview.component';
import UploadAssets from './upload-assets.component';
import {
  checkAdminUserRole,
  getLocalValue,
} from '../../utils/local-cache.util';
import SearchList from '../table-elements/search-list.component';
import Categories from '../form-elements/categories.component';

const ListVideos = ({ videoContentType }) => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: 'All',
    },
  });
  const categoryWatch = watch('category');

  const [adminUserRole, setAdminUserRole] = useState('');

  const renderColumns = [];
  columns.forEach((item) => {
    if (adminUserRole === 'ADMIN') {
      if (videoContentType === 'AD') {
        if (item.dataKey !== 'categoryName') {
          renderColumns.push(item);
        }
      } else if (videoContentType === 'VIDEO') {
        if (item.dataKey !== 'adPlaysCount') {
          renderColumns.push(item);
        }
      } else {
        renderColumns.push(item);
      }
    } else if (adminUserRole === 'CONTENT_OWNER' && item.dataKey !== 'status') {
      renderColumns.push(item);
    }
  });

  const renderActions = [];
  actions.forEach((item) => {
    if (adminUserRole === 'CONTENT_OWNER') {
      if (!MULTIPLE_CAT_PER_VIDEO) {
        if (item.action !== 'assign_books' && item.action !== 'delete') {
          renderActions.push(item);
        }
      } else if (item.action !== 'delete') {
        renderActions.push(item);
      }
    } else if (!MULTIPLE_CAT_PER_VIDEO || videoContentType === 'AD') {
      if (item.action !== 'assign_books') {
        renderActions.push(item);
      }
    } else {
      renderActions.push(item);
    }
  });

  const searchVideosRef = useRef(null);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [videoPlayer, setVideoPlayer] = useState({
    show: false,
    videoUrl: '',
    title: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    imageUrl: '',
    title: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleFileDownload = (url, filename = '') => {
    const downloadLink = url;
    const downloadFileName = filename || url.substr(url.lastIndexOf('/') + 1);
    const a = document.createElement('a');
    a.href = downloadLink;
    a.download = `${downloadFileName}`;
    a.target = '_blank';
    a.click();
  };

  const handleClickEvent = (video, type = '') => {
    let uri = `videoID=${video.id}`;
    uri += `&videoContentType=${videoContentType}`;

    getPlayableStream(uri)
      .then((res) => {
        if (type) {
          handleFileDownload(res.data.downloadUrl);
        } else {
          setVideoPlayer({
            ...videoPlayer,
            show: true,
            title: video.title,
            videoUrl: res.data.videoUrl,
          });
        }
      })
      .catch(() =>
        setTimeout(() => {
          setVideoPlayer({
            ...videoPlayer,
            show: false,
            title: '',
            videoUrl: '',
          });
        }, 5000)
      );
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });

    if (selectedAction === ACTIONS.DOWNLOAD.value)
      handleClickEvent(selectedData, selectedAction);
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteVideoById(doAction.data.id)
      .then(() => {
        handleActionSuccess(
          `${
            videoContentType === 'VIDEO'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          } deleted successfully.`
        );
      })
      .catch((e) => {
        const message =
          e.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleImagePreview = (rowData) => {
    setImagePreview({
      ...imagePreview,
      show: true,
      imageUrl: rowData.thumbnailUrl,
      title: rowData.title,
    });
  };

  const handleSyncCDNStatus = (contentType) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    syncVideoCDNStatus(contentType)
      .then((res) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `${res.data?.updatedRowsCount} data updated.`,
        });

        if (res.data?.updatedRowsCount > 0) {
          setOptions({ ...options, reloadCounter: options.reloadCounter + 1 });
        }
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleToggleStatus = (r, value) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      id: r.id,
      status: value ? 0 : 1,
      type: 'video',
    };

    toggleStatus(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Status updated.',
        });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchVideos = () => {
    setOptions({
      ...options,
      q: searchVideosRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
    });
  };

  const handleClearSearch = () => {
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
    });
    searchVideosRef.current.value = '';
  };

  const dataListAPICall = () => {
    const adminUserId = getLocalValue(LS_ADMIN_USER_ID);
    const getAdminRole = checkAdminUserRole();
    setAdminUserRole(getAdminRole);

    let uri = 'videos';
    uri += `?type=${videoContentType}`;
    uri += `&adminUserId=${adminUserId}`;
    uri += `&adminUserRole=${getAdminRole}`;
    uri += `&page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    } else if (searchVideosRef.current?.value) {
      uri += `&q=${searchVideosRef.current.value}`;
    }
    if (categoryWatch && Number(categoryWatch)) {
      uri += `&category=${categoryWatch}`;
    }
    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
          q: null,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
          q: null,
        });
      });
  };

  useEffect(() => {
    const newOptions = options;
    newOptions.page = 0;
    setOptions({
      ...options,
      page: 0,
    });

    if (searchVideosRef.current?.value) {
      searchVideosRef.current.value = null;
    }

    dataListAPICall(newOptions);
  }, [videoContentType]);

  useEffect(() => {
    dataListAPICall();
  }, [options.reloadCounter, categoryWatch]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="fe:sync" />}
            sx={{
              marginLeft: '5px',
            }}
            onClick={() => handleSyncCDNStatus(videoContentType)}
          >
            Sync CDN Status
          </Button>

          {videoContentType === 'VIDEO' && !MULTIPLE_CAT_PER_VIDEO && (
            <Controller
              name="category"
              control={control}
              sx={{
                marginLeft: '5px',
              }}
              render={({ field: { onChange, value } }) => (
                <Categories
                  id="category"
                  name="category"
                  label={`Select ${CONTENT_ALIAS.CATEGORY_PLURAL}`}
                  defaultValue={value}
                  onChange={onChange}
                  sx={{
                    width: '200px',
                    height: '36px',
                  }}
                  parentSx={{
                    width: '200px',
                    height: '36px',
                    marginLeft: '5px',
                  }}
                  labelSx={{
                    transform:
                      !categoryWatch &&
                      'translate(14px, 8px) scale(1) !important',
                  }}
                  error={errors?.category?.message || ''}
                  options={options}
                  addDefault
                  allowAllOptions
                  menuProps={{ PaperProps: { sx: { width: '450px' } } }}
                />
              )}
            />
          )}

          <SearchList
            label={
              videoContentType === 'VIDEO'
                ? CONTENT_ALIAS.VIDEO_PLURAL
                : CONTENT_ALIAS.AD_PLURAL
            }
            handleSearch={handleSearchVideos}
            inputRef={searchVideosRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
            loading={options.loading}
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            disabled={options.loading}
            onClick={handleRefreshData}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={renderColumns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={renderActions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          onCustomClick={handleClickEvent}
          handleImagePreview={handleImagePreview}
          handleToggleStatus={handleToggleStatus}
          adminUserRole={adminUserRole}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditVideos
          title={`Add New ${
            videoContentType === 'VIDEO'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          }`}
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
          videoContentType={videoContentType}
          adminUserRole={adminUserRole}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditVideos
          title={`Edit ${
            videoContentType === 'VIDEO'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          } Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
          videoContentType={videoContentType}
          adminUserRole={adminUserRole}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewVideos
          title={`${
            videoContentType === 'VIDEO'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          } Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          videoContentType={videoContentType}
          adminUserRole={adminUserRole}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title={`Delete ${
            videoContentType === 'VIDEO'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          }`}
          message={`Do you want to delete ${doAction.data.title} ${
            videoContentType === 'VIDEO'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          }? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {doAction.action === ACTIONS.ASSIGN_CATEGORIES.value && (
        <AssignCategoryVideos
          title={`Assign ${CONTENT_ALIAS.CATEGORY_PLURAL} to ${doAction.data.title} ${CONTENT_ALIAS.VIDEO_SINGULAR}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_ASSETS.value && (
        <UploadAssets
          title={`Upload Assets - ${doAction.data.title}`}
          dataId={doAction.data.id}
          dataTitle={doAction.data.title}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          videoContentType={videoContentType}
        />
      )}

      {videoPlayer.show && (
        <VideoPreview
          videoPlayer={videoPlayer}
          setVideoPlayer={setVideoPlayer}
        />
      )}

      {imagePreview.show && (
        <ImagePreview
          title={`Thumbnail - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.imageUrl}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListVideos.propTypes = {
  videoContentType: PropTypes.string.isRequired,
};

export default ListVideos;
