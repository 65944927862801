import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

// Categories module configurations
export const LISTING_COLUMNS = [
  // Removed for now
  // Will need this in future when support is added
  {
    id: 'bannerImageUrl',
    label: 'Image Preview',
    width: '5%',
    align: 'center',
    dataKey: 'bannerImageUrl',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'title',
    label: 'Title',
    width: '20%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '15%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'showOnVOD',
    label: 'Show On VOD',
    width: '15%',
    align: 'center',
    dataKey: 'showOnVOD',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'isFeatured',
    label: 'Is Featured',
    width: '15%',
    align: 'center',
    dataKey: 'isFeatured',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'isPlaylist',
    label: 'Is Playlist',
    width: '15%',
    align: 'center',
    dataKey: 'isPlaylist',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'allowSubCategories',
    label: 'Allow Only Sub Categories',
    width: '10%',
    align: 'center',
    dataKey: 'allowSubCategories',
    sortable: true,
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'videosCount',
    label: `Total ${CONTENT_ALIAS.CONTENT}`,
    width: '10%',
    align: 'center',
    dataKey: 'videosCount',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '20%',
    align: 'center',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified Date',
    width: '20%',
    align: 'center',
    dataKey: 'modifiedAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_BANNER.value,
    label: ACTIONS.UPLOAD_BANNER.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_CATEGORIES_COLUMNS = [
  {
    id: 'name',
    label: 'Title',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'showOnVOD',
    label: 'Show On VOD',
    dataKey: 'showOnVOD',
    type: CELL_DATA_TYPES.BOOL,
  },
  // Commented for now
  // Will require to add support in future
  {
    id: 'bannerImageUrl',
    label: 'Banner Image Url',
    dataKey: 'bannerImageUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified Date',
    dataKey: 'modifiedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
