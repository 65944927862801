import axios from 'axios';
import { getDeviceId } from '../utils/device-info.util';
import { getLocalValue } from '../utils/local-cache.util';
import { LS_ADMIN_USER_AUTH_TOKEN } from '../config/const.config';

const prepareRequestOptions = (url, method, data = null) => {
  const baseAPIUrl = process.env.REACT_APP_CMS_API_ENDPOINT;
  const uuid = getDeviceId();
  const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);

  const options = {
    url: `${baseAPIUrl}${url}`,
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Req-Device': uuid,
      'X-Admin-Auth-Token': adminAuth,
    },
    data: JSON.stringify(data || {}),
  };

  return options;
};

export const getCounters = async () => {
  const options = prepareRequestOptions('/reports/counters', 'GET');

  const response = await axios(options);
  return response.data;
};

export const getRealtimeDashboard = async (payload) => {
  const options = prepareRequestOptions(
    '/reports/realtime-dashboard',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getVideosReportData = async (payload) => {
  const options = prepareRequestOptions(
    '/reports/export/videos',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getAdsReportData = async (payload) => {
  const options = prepareRequestOptions('/reports/export/ads', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const getDataList = async (type) => {
  const options = prepareRequestOptions(
    `/reports/list/sessions/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const viewSessionById = async (sessionType, id) => {
  const options = prepareRequestOptions(
    `/reports/session/${sessionType}/${id}/view`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getVideos = async () => {
  const options = prepareRequestOptions('/reports/list/videos', 'GET');

  const response = await axios(options);
  return response.data;
};

export const getSummary = async (sDate, eDate) => {
  const options = prepareRequestOptions(
    `/reports/summary?startDate=${sDate}&endDate=${eDate}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};
