import React, { useEffect, useState } from 'react';
import { Stack, Grid, Container, Card, Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import Datepicker from '../form-elements/datepicker.component';
import { getSummary } from '../../services/reports.service';
import { formatDate, getDate } from '../../utils/datetime.util';
import PLATFORMS_LIST, { CHART_COLORS } from '../../config/platforms.config';

const PieChart = () => {
  const [seriesData, setSeriesData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [sessionPCount, setSessionPCount] = useState([]);
  const [colors, setColors] = useState([]);

  const [filters, setFilters] = useState({
    channel: null,
    startDate: getDate(-7),
    endDate: getDate(0),
    reloadCounter: 0,
    loading: true,
  });

  const optionsSeries = {
    series: seriesData,
    labels: xLabels,
    colors,
    legend: {
      position: 'bottom',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const handleStartDateChange = (v) => {
    if (filters.loading) return;
    setFilters({
      ...filters,
      startDate: v,
      reloadCounter: filters.reloadCounter + 1,
      loading: true,
    });
  };

  const handleEndDateChange = (v) => {
    if (filters.loading) return;
    setFilters({
      ...filters,
      endDate: v,
      reloadCounter: filters.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');

    getSummary(startDate, endDate)
      .then((res) => {
        const le = [];
        const platform = [];
        const sC = [];
        const colorMap = [];

        res.data.forEach((pd) => {
          const platformData = PLATFORMS_LIST.find((p) => p.id === pd.platform);
          le.push(parseFloat(pd.durationPercentage));
          platform.push(platformData.title);
          sC.push(parseFloat(pd.sessionPercentageCount));
          if (!colorMap.includes(CHART_COLORS[pd.platform.toUpperCase()])) {
            colorMap.push(CHART_COLORS[pd.platform.toUpperCase()]); // Assign colors based on platform
          }
        });

        setSeriesData(le);
        setSessionPCount(sC);
        setXLabels(platform);
        setColors(colorMap); // Set the dynamic colors for the chart

        setFilters({ ...filters, loading: false });
      })
      .catch(() => {
        setFilters({ ...filters, loading: false });
      });
  }, [filters.reloadCounter]);

  return (
    <Box sx={{ pt: 3 }}>
      <Card>
        <Grid item xs={12} sx={{ p: 3, pb: 1 }}>
          <Stack direction="row" justifyContent="right" gap={2}>
            <Datepicker
              id="dr_start_date"
              name="dr_start_date"
              label="Start Date"
              defaultValue={filters.startDate}
              onChange={handleStartDateChange}
              sx={{ marginRight: 2, width: 200 }}
              readOnly={filters.loading}
            />
            <Datepicker
              id="dr_end_date"
              name="dr_end_date"
              label="End Date"
              defaultValue={filters.endDate}
              onChange={handleEndDateChange}
              sx={{ width: 200 }}
              readOnly={filters.loading}
            />
          </Stack>
        </Grid>

        {seriesData.length <= 0 && (
          <Grid
            direction="row"
            maxWidth="lg"
            height={400}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <div style={{ fontWeight: 700 }}>
              No reports available for Video play duration and session count
              summary per platform.
            </div>
          </Grid>
        )}

        {seriesData.length > 0 && (
          <Container maxWidth="lg">
            <Grid container xs={12}>
              <Grid container xs={12} md={6} sm={6} justifyContent="center">
                <ReactApexChart
                  type="pie"
                  series={seriesData}
                  options={optionsSeries}
                  height={364}
                />
                <div style={{ fontWeight: 700 }}>
                  Video Play Duration Summary per Platform
                </div>
              </Grid>
              <Grid container xs={12} md={6} sm={6} justifyContent="center">
                <ReactApexChart
                  type="pie"
                  series={sessionPCount}
                  options={optionsSeries}
                  height={364}
                />
                <div style={{ fontWeight: 700 }}>
                  Video Play Session Count Summary per Platform
                </div>
              </Grid>
            </Grid>
          </Container>
        )}
      </Card>
    </Box>
  );
};

export default PieChart;
