import CELL_DATA_TYPES from '../cell-types.config';

// Channels module configurations
export const LISTING_COLUMNS = [
  {
    id: 'code',
    label: 'Code',
    width: '20%',
    align: 'left',
    dataKey: 'code',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'title',
    label: 'Title',
    width: '25%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '20%',
    align: 'right',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '25%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [];
