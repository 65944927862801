import React, { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewCategory,
  viewCategoryById,
  updateCategoryById,
  getSubCategoryList,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import {
  CONTENT_ALIAS,
  DEFAULT_CHANNEL_VALUE,
} from '../../config/const.config';
import SearchableCategory from '../form-elements/searchableCategory.component';
import ConfirmPopup from '../common/confirm-popup.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditCategories = ({ title, dataId, onCancel, onSuccess }) => {
  const isParentCategory = useRef(null);
  const formData = useRef(null);
  const categoryData = useRef(null);
  const confirmationMessage = useRef('');
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      status: null,
      description: '',
      bannerImageUrl: '',
      isFeatured: 0,
      isPlaylist: 0,
      allowSubCategories: 0,
      subCategories: [],
    },
  });

  const watchFields = watch(['allowSubCategories', 'isFeatured', 'isPlaylist']);

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [isConfirmationPromptOpen, setIsConfirmationPromptOpen] =
    useState(false);

  const saveNewData = (payload) => {
    createNewCategory(payload)
      .then(() => {
        onSuccess(`New ${CONTENT_ALIAS.CATEGORY_SINGULAR} added successfully.`);
        formData.current = null;
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
        formData.current = null;
      });
  };

  const updateExistingData = (payload) => {
    updateCategoryById(dataId, payload)
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.CATEGORY_SINGULAR} details updated successfully.`
        );
        formData.current = null;
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
        formData.current = null;
      });
  };

  const loadSubCategoryOptions = (params, callBack) => {
    getSubCategoryList(params).then((res) => {
      callBack(res.data);
    });
  };

  const onFormSubmit = (data) => {
    if (
      !isConfirmationPromptOpen &&
      data.allowSubCategories &&
      isParentCategory.current
    ) {
      formData.current = data;
      if (data.allowSubCategories && isParentCategory.current) {
        confirmationMessage.current =
          'Are you sure, You want to proceed? It will remove sub category assign to other categories';
        setIsConfirmationPromptOpen(true);
        return;
      }
    }
    if (
      !isConfirmationPromptOpen &&
      data.allowSubCategories &&
      categoryData?.current?.videosCount > 0
    ) {
      formData.current = data;
      confirmationMessage.current =
        'Are you sure, You want to proceed? It will remove all the videos assign to this category';
      formData.current.resetVideos = true;
      setIsConfirmationPromptOpen(true);
      return;
    }
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const filteredSubCategories = [];

    const finalData = data || formData.current;

    finalData.subCategories.forEach((d) => {
      if (typeof d === 'object') filteredSubCategories.push(d.id);
      else filteredSubCategories.push(d);
    });

    const payload = {
      name: finalData.name.trim(),
      status: finalData.status,
      description: finalData.description.trim(),
      bannerImageUrl: finalData.bannerImageUrl,
      isFeatured: finalData.isFeatured,
      isPlaylist: finalData.isPlaylist,
      allowSubCategories: finalData.allowSubCategories,
      subCategories: filteredSubCategories,
      channelId: DEFAULT_CHANNEL_VALUE,
    };

    if (formData.current && formData.current.resetVideos)
      payload.resetVideos = true;
    if (dataId) {
      // remove current category from subcategory
      if (finalData.allowSubCategories && isParentCategory.current)
        payload.isParentCategory = true;
      updateExistingData(payload);
    } else {
      payload.showOnVOD = 0;

      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewCategoryById(dataId)
      .then((res) => {
        isParentCategory.current = res.data?.isParentCategory;
        const filteredSubCategories = [];
        categoryData.current = res.data;

        res.data.subCategories.forEach((d) => {
          filteredSubCategories.push(d);
        });

        // Fill form values
        setValue('name', res.data?.name || '');
        setValue('status', res.data?.status || 0);
        setValue('description', res.data?.description || '');
        setValue('bannerImageUrl', res.data?.bannerImageUrl || '');
        setValue('isFeatured', res.data?.isFeatured ? 1 : 0 || 0);
        setValue('isPlaylist', res.data?.isPlaylist ? 1 : 0 || 0);
        setValue('subCategories', filteredSubCategories || []);
        setValue('allowSubCategories', res.data?.allowSubCategories || 0);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <>
      <Dialog
        open
        aria-labelledby="add-dialog-title"
        aria-describedby="add-dialog-description"
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="view-dialog-title">{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          size="small"
          sx={{
            position: 'absolute',
            right: 10,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="ic:outline-close" />
        </IconButton>

        <DialogContent dividers id="add-dialog-description">
          {loading && <Loading />}

          {!loading && (
            <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      ...validationRules.REQUIRED,
                      ...{ ...maxLengthValidation(255) },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={`${CONTENT_ALIAS.CATEGORY_SINGULAR} Name`}
                        type="text"
                        error={!!errors.name}
                        helperText={errors?.name?.message || null}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="status"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Status"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1, 0]}
                        sx={{ width: '100%' }}
                        error={errors?.status?.message || ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      ...validationRules.REQUIRED,
                      ...{ ...maxLengthValidation(1500) },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Description"
                        type="text"
                        error={!!errors.description}
                        helperText={errors?.description?.message || null}
                        fullWidth
                        multiline
                        rows={3}
                      />
                    )}
                  />
                </Grid>

                {/* Commented for now
              Will require to add support in future */}
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="bannerImageUrl"
                      control={control}
                      rules={{
                        ...validationRules.OPTIONAL_URL,
                        ...{ ...maxLengthValidation(255) },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Banner Image Url"
                          type="text"
                          error={!!errors.bannerImageUrl}
                          helperText={errors?.bannerImageUrl?.message || null}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="allowSubCategories"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Allow Only Sub Categories"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1, 0]}
                        sx={{ width: '100%' }}
                        error={errors?.allowSubCategories?.message || ''}
                        type="acceptance"
                        isDisabled={watchFields[1] || watchFields[2]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  {watchFields[0] === 1 && (
                    <Controller
                      name="subCategories"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <SearchableCategory
                          id="subCategories"
                          name="subCategories"
                          label="Select Sub-Categories"
                          defaultValue={value}
                          multiple
                          onChange={onChange}
                          sx={{ width: '100%' }}
                          error={errors?.subCategories?.message || ''}
                          dataId={dataId}
                          loadOption={loadSubCategoryOptions}
                        />
                      )}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="isFeatured"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Is Featured"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1, 0]}
                        sx={{ width: '100%' }}
                        error={errors?.isFeatured?.message || ''}
                        type="acceptance"
                        isDisabled={watchFields[0] === 1}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="isPlaylist"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Is Playlist"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1, 0]}
                        sx={{ width: '100%' }}
                        error={errors?.isPlaylist?.message || ''}
                        type="acceptance"
                        isDisabled={watchFields[0] === 1}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </DialogActions>

        {snackbarInfo.show && (
          <SnackbarInfo
            type={snackbarInfo.type}
            message={snackbarInfo.message}
          />
        )}
      </Dialog>
      {isConfirmationPromptOpen && (
        <ConfirmPopup
          title="Confirm Action"
          message={confirmationMessage.current}
          onCancel={() => setIsConfirmationPromptOpen(false)}
          onSuccess={() => {
            onFormSubmit();
            setIsConfirmationPromptOpen(false);
          }}
          cancelButtonText="No"
          successButtonText="Yes"
        />
      )}
    </>
  );
};

AddEditCategories.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditCategories;
